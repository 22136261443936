import { ReactNode, useState } from 'react'
import {
  Box,
  Container,
  Flex,
  Input,
  Link as LinkChakra,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react'
import RouterUrl from '@constants/router.constant'
import { medusaRequest } from '@lib/config'
import Link from 'next/link'
import {
  FacebookIcon,
  InstagramIcon,
  LongArrow,
  TikTokIcon,
  YoutubeIcon
} from '@icons/index'
import Image from 'next/image'
import { toast } from 'react-toastify'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'

import visa from '@images/accept-payment/visa.png'
import mastercard from '@images/accept-payment/mastercard.png'
import atome from '@images/accept-payment/atome.png'
import alipay from '@images/accept-payment/alipay.png'
import paynow from '@images/accept-payment/paynow.png'
import paylah from '@images/accept-payment/paylah.png'
import netspay from '@images/accept-payment/netspay.png'
import wechatpay from '@images/accept-payment/wechat_pay.png'
import shopeepay from '@images/accept-payment/shopeepay.png'

import { CAPCHA, MAILCHIMP } from '@lib/constants'
import { sendMailNewSubscription } from '@lib/util/subcription-handle'
import { useRouter } from 'next/router'

import { ContactType, mapToLinkContact } from '@lib/util/link-contact'
import FooterInfo from '../footer-info'
import FooterCopyright from '@components/footer/footer-copyright'

const SocialUrl = {
  facebook: 'https://www.facebook.com/habbitsgp',
  youtube: 'https://www.youtube.com/@jmlsgp',
  instagram: 'https://www.instagram.com/habbit.sg/',
  tiktok: 'https://www.tiktok.com/@habbit.sg'
}

const PAYMENTS = [
  { icon: visa, title: 'visa', width: 34, height: 34 },
  { icon: mastercard, title: 'mastercard', width: 34, height: 34 },
  { icon: paynow, title: 'paynow', width: 34, height: 34 },
  { icon: atome, title: 'atome', width: 96, height: 35 },
  { icon: alipay, title: 'alipay', width: 34, height: 34 },
  { icon: paylah, title: 'paylah', width: 34, height: 34 },
  { icon: netspay, title: 'netspay', width: 34, height: 34 },
  { icon: wechatpay, title: 'wechatpay', width: 34, height: 34 },
  { icon: shopeepay, title: 'shopeepay', width: 72, height: 33 }
]

export const ListHeader = ({
  children,
  visibility,
  textStyle = 'smallTitle',
  color
}: {
  children: ReactNode
  textStyle?: string
  visibility?: any
  color?: string
}) => {
  return (
    <Text
      textStyle={textStyle}
      mb="2!"
      visibility={visibility || 'visible'}
      color={color}
    >
      {children}
    </Text>
  )
}

const ListSubHeader = ({
  children,
  textStyle = 'listHeaderSmall',
  onClick,
  cursor
}: {
  children: ReactNode
  textStyle?: string
  onClick?: () => void
  cursor?: any
} & any) => {
  return (
    <Text
      textStyle={textStyle}
      lineHeight="24px"
      onClick={onClick}
      cursor={cursor}
    >
      {children}
    </Text>
  )
}

interface PropsType {
  color?: string
  bg?: string
  subscribeColor?: string
}

export default function Footer({ color, bg, subscribeColor }: PropsType) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [email, setEmail] = useState<string>('')

  const router = useRouter()

  const handleSubscription = async () => {
    if (!email) {
      toast('Please enter email to subscription', {
        type: 'error'
      })

      return
    }

    if (!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      toast('Your mail is incorrect', {
        type: 'error'
      })

      return
    }

    if (typeof executeRecaptcha !== 'function') {
      return
    }

    try {
      const gReCaptchaToken = await executeRecaptcha('subscription')
      const body = {
        gReCaptchaToken
      }

      const { data: verify } = await axios.post(CAPCHA.siteverify, body)

      if (verify.status === 'success') {
        try {
          await medusaRequest('POST', MAILCHIMP.subscription, {
            email: email
          })

          await sendMailNewSubscription(email)

          toast('Subscription success', {
            type: 'success'
          })
        } catch (e) {
          toast('Your email is already a list member', {
            type: 'success'
          })
        }
      }
    } catch (e) {
      toast((e as any).message, {
        type: 'error'
      })
    }
  }

  return (
    <Box
      color={'black'}
      borderTop={'1px solid'}
      borderTopColor={'bg'}
      mt={'auto'}
    >
      <Container
        as={Stack}
        // maxW={CONTAINER_MAX_WIDTH}
        py={24}
        spacing={0}
      >
        <SimpleGrid
          templateColumns={{
            sm: '1fr',
            lg: '6fr 2fr 2fr 2fr 2fr',
            xl: '5fr 2fr 2fr 2fr 2fr'
          }}
          spacing={{
            md: 10,
            base: 6
          }}
        >
          <Box>
            <Stack gap={1}>
              <ListHeader textStyle="listHeaderLarge">Stay Updated</ListHeader>
              <Text textStyle="listItemSmall">
                Subscribe now, so you won’t miss any deals we have in store for
                you, and get a 5% off your first purchase.
              </Text>
              <Flex
                bg={subscribeColor ?? 'bg'}
                px={4}
                py={2}
                fontSize={'sm'}
                alignItems={'center'}
                justifyContent={'space-between'}
                maxWidth={{
                  lg: 'initial',
                  base: 300
                }}
              >
                <Input
                  color={subscribeColor ? 'white' : 'black'}
                  placeholder={'Email Address'}
                  _placeholder={{
                    color: subscribeColor ? 'white' : 'black'
                  }}
                  style={{
                    padding: 0,
                    borderWidth: 0
                  }}
                  _focus={{
                    boxShadow: 'none !important'
                  }}
                  type="email"
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      handleSubscription()
                    }
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
                <div className="cursor-pointer" onClick={handleSubscription}>
                  <LongArrow color={subscribeColor ? 'white' : 'black'} />
                </div>
              </Flex>
            </Stack>

            <Stack
              mt={{
                lg: 12,
                base: 8
              }}
            >
              <ListHeader color={'doveGray'}>Accepted Payments</ListHeader>
              <Flex gap={4} flexWrap="wrap" alignItems="center">
                {PAYMENTS.map((image, key) => (
                  <div key={key}>
                    <Image
                      src={image.icon}
                      alt={image.title}
                      height={image.height}
                      width={image.width}
                      quality={100}
                    />
                  </div>
                ))}
              </Flex>
            </Stack>
          </Box>

          <FooterInfo />

          <Stack align={'flex-start'} spacing={2}>
            <ListHeader>Contact</ListHeader>
            <Stack spacing={1}>
              <ListSubHeader>Enquiry</ListSubHeader>
              <Link
                href={mapToLinkContact(ContactType.EMAIL, 'help@habbit.sg')}
              >
                <Text textStyle="listItem">help@habbit.sg</Text>
              </Link>
            </Stack>
            <Stack spacing={1}>
              <ListSubHeader>Customer Service:</ListSubHeader>
              <Link href={mapToLinkContact(ContactType.PHONE, '+65 6742 1771')}>
                <Text textStyle="listItem">+65 6742 1771</Text>
              </Link>
            </Stack>
            <Stack spacing={1}>
              <ListSubHeader>Ordering hotline:</ListSubHeader>
              <Link href={mapToLinkContact(ContactType.PHONE, '+65 6742 2007')}>
                <Text textStyle="listItem">+65 6742 2007</Text>
              </Link>
            </Stack>
          </Stack>

          <Stack align={'flex-start'} spacing={2} w={'70%'}>
            <ListHeader visibility="hidden">Address</ListHeader>
            <Stack spacing={0}>
              <ListSubHeader>Address</ListSubHeader>
              <Text textStyle="listItem" lineHeight="27px">
                10 Changi South Lane #01-02,
              </Text>
              <Text textStyle="listItem" mt="0.5!">
                Singapore 486162
              </Text>
            </Stack>
            <Stack spacing={0}>
              <ListSubHeader
                cursor={'pointer'}
                onClick={() =>
                  router.push(`${RouterUrl.CONTACT_US}#experience-store`)
                }
              >
                Store Locator
              </ListSubHeader>
              <Flex gap={3} mt={'3 !important'}>
                <LinkChakra
                  href={SocialUrl.facebook}
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FacebookIcon />
                </LinkChakra>
                <Link
                  href={SocialUrl.youtube}
                  target="_blank"
                  aria-label="Youtube"
                >
                  <YoutubeIcon />
                </Link>
                <Link
                  href={SocialUrl.instagram}
                  target="_blank"
                  aria-label="Instagram"
                >
                  <InstagramIcon />
                </Link>
                <Link
                  href={SocialUrl.tiktok}
                  target="_blank"
                  aria-label="Tiktok"
                >
                  <TikTokIcon />
                </Link>
              </Flex>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <FooterCopyright bg={bg} color={color} />
    </Box>
  )
}
