import { BACKEND_URL_STORE } from '@constants/server.constant'
import axios from 'axios'

export const listFooterInfo = async () => {
  return await axios.get(`${BACKEND_URL_STORE}/footer/info`)
}

export const getFooterInfoById = async (id: string) => {
  return await axios.put(`${BACKEND_URL_STORE}/footer/info/${id as string}`)
}
