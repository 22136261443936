import { Fragment, useState } from 'react'
import dynamic from 'next/dynamic'
import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon, RepeatIcon } from '@chakra-ui/icons'
import { CartIcon, SearchIcon, UserIcon } from '@icons/index'
import Link from 'next/dist/client/link'
import { useResponsive } from '@common/hooks'
import RouterUrl from '@constants/router.constant'
import AuthFilter from './AuthFilter'
import { useCart } from 'medusa-react'
import { useAccount } from '@lib/context/account-context'
import CountrySelectNav from './country-select-nav'
import Search from './search'
import { Link as ScrollLink } from 'react-scroll'
import useAdvocado from '@common/hooks/use-advocado'
import { FilterQueryParams } from '@constants/filter.constant'

const HabbitPointIcon = dynamic(() => import('@icons/habbit-point-icon'), {
  ssr: false
})

interface NavbarPropsType {
  navItems: any[]
  name?: string
  color?: string
  productSection: any
  logo: React.ReactNode
  onRedirect: () => void
  brand?: any
}

const NavItems = ({
  items,
  color,
  brand,
  onClose
}: {
  items: any[]
  color?: string
  brand?: any
  onClose?: () => void
}) => {
  return (
    <Fragment>
      {items.map((item: any) => {
        const link =
          item.label === 'Shop' && brand != null
            ? `${item.href as string}?${FilterQueryParams.BRANDS}=${
                brand.handle as string
              }`
            : item.href

        return (
          <Link
            key={item.label}
            href={link}
            onClick={() => {
              if (onClose) {
                onClose()
              }
            }}
          >
            <Text
              textStyle="linkItem"
              py={0.5}
              color={color ? 'white' : 'black'}
            >
              {item.label}
            </Text>
          </Link>
        )
      })}
    </Fragment>
  )
}

const CountrySelectNavCustom = ({ color }: { color?: string }) => (
  <CountrySelectNav
    bg={color ? `${color} !important` : undefined}
    color={color ? 'black' : undefined}
    textColor={color ? 'white' : 'black'}
  />
)

export default function Navbar({
  navItems,
  color,
  logo,
  productSection,
  onRedirect,
  brand
}: NavbarPropsType) {
  const { xlMax, lgMin, mdMax } = useResponsive()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { totalItems } = useCart()
  const { customer } = useAccount()

  const { handleLogout, avocado } = useAccount()

  const [isSearch, setIsSearch] = useState(false)

  const { verifyAdvocado, loadAdvocadoPDPAModal, loadAdvocadoSignupModal } =
    useAdvocado()

  return (
    <Box
      bg={color ?? 'bg'}
      px={{ lg: 2 }}
      w={'100%'}
      zIndex={1000}
      position={'fixed'}
      top={0}
    >
      <Container px={0}>
        <Box py={0} color={'black !important'}>
          <Container>
            <Flex
              minH={xlMax ? '78px' : '96px'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex
                flex={{ base: 1, lg: 80 }}
                display={{ base: 'flex', lg: 'none' }}
              >
                <IconButton
                  onClick={onToggle}
                  icon={
                    isOpen ? (
                      <CloseIcon w={5} h={5} />
                    ) : (
                      <HamburgerIcon w={8} h={8} />
                    )
                  }
                  color={color ? 'white !important' : 'black !important'}
                  variant={'ghost'}
                  aria-label={'Toggle Navigation'}
                  _hover={{
                    bg: color ?? 'bg'
                  }}
                />
              </Flex>

              <Flex
                alignSelf="center"
                justifyContent={{
                  base: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'initial'
                }}
                flex={{ base: 2 }}
                maxW={{
                  lg: '200px',
                  md: '170px',
                  sm: '140px',
                  base: '100px'
                }}
                maxH={{ lg: '66x' }}
                cursor={'pointer'}
                onClick={onRedirect}
              >
                {logo}
              </Flex>

              <Flex
                alignItems="center"
                color={color ? 'white !important' : 'black !important'}
              >
                <Flex
                  justifyContent={'end'}
                  gap={{
                    lg: customer ? 8 : 10,
                    xl: customer ? 12 : 14,
                    '2xl': 16
                  }}
                  display={{ base: 'none', lg: 'flex' }}
                  alignItems="center"
                >
                  <NavItems items={navItems} color={color} brand={brand} />

                  {productSection != null && (
                    <Box cursor="pointer">
                      <ScrollLink
                        to="products"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                      >
                        <Text
                          textStyle="linkItem"
                          py={0.5}
                          color={
                            color ? 'white !important' : 'black !important'
                          }
                        >
                          Products
                        </Text>
                      </ScrollLink>
                    </Box>
                  )}

                  {!isSearch && lgMin && (
                    <Flex
                      gap={{ lg: customer ? 3 : 8, xl: 8, '2xl': 8 }}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Flex alignItems="center" gap={5}>
                        <AuthFilter filter={true} isLogin={false}>
                          <Link href={RouterUrl.AUTH.LOGIN}>
                            <Text
                              textStyle="linkItem"
                              color={color ? 'white' : 'black'}
                            >
                              Login
                            </Text>
                          </Link>
                        </AuthFilter>

                        <AuthFilter filter={true} isLogin={true}>
                          <Flex
                            alignItems={'center'}
                            gap={1.5}
                            bg={'white'}
                            px={{ lg: 2.5, xl: 3 }}
                            py={{ lg: 1, xl: 2 }}
                            borderRadius={15}
                            onClick={verifyAdvocado}
                            cursor={'pointer'}
                          >
                            <HabbitPointIcon w={4} h={4} />
                            <Text textStyle={'joinMembership'} w={'auto'}>
                              {avocado?.avocadoInfo?.pdpa == '1' ? (
                                (
                                  avocado?.avocadoInfo?.campaignBalances?.find(
                                    (item: any) =>
                                      item.campaignType == 'loyalty'
                                  ).points as number
                                ).toLocaleString()
                              ) : (
                                <div
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                >
                                  Get Points & Rebate
                                </div>
                              )}
                            </Text>
                          </Flex>
                        </AuthFilter>
                      </Flex>

                      <Flex gap={5} alignItems="center" mt={-1}>
                        <AuthFilter>
                          <Stack>
                            <Link href={RouterUrl.ACCOUNT.ROOT}>
                              <UserIcon color={color ? 'white' : 'black'} />
                            </Link>
                          </Stack>
                        </AuthFilter>
                        <Stack position={'relative'}>
                          <Link href={RouterUrl.CART.ROOT} aria-label="Cart">
                            <CartIcon color={color ? 'white' : 'black'} />
                          </Link>
                          {totalItems > 0 && (
                            <Flex
                              position={'absolute'}
                              w={5}
                              h={5}
                              top={-4}
                              left={3}
                              bg={'red'}
                              color={'white'}
                              p={1}
                              borderRadius={'50%'}
                              fontSize={10}
                              alignItems={'center'}
                              justifyContent={'center'}
                              zIndex={10}
                            >
                              {totalItems}
                            </Flex>
                          )}
                        </Stack>
                        <Stack
                          onClick={() => {
                            setIsSearch(!isSearch)
                          }}
                          cursor={'pointer'}
                          py={0}
                        >
                          <SearchIcon color={color ? 'white' : 'black'} />
                        </Stack>
                        <CountrySelectNavCustom color={color} />
                      </Flex>
                    </Flex>
                  )}
                  {isSearch && (
                    <Flex gap={5} alignItems={'center'}>
                      <Search
                        colorIcon={color ? 'white' : 'black'}
                        colorText={color ? 'white' : 'black'}
                      />
                      <Stack
                        cursor={'pointer'}
                        onClick={() => {
                          setIsSearch(!isSearch)
                        }}
                      >
                        <RepeatIcon boxSize={5} />
                      </Stack>
                      <CountrySelectNavCustom color={color} />
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Flex
                flex={{ base: 1, xl: 'auto' }}
                display={{ base: 'flex', lg: 'none' }}
                justifyContent="flex-end"
                alignItems={'center'}
                gap={5}
              >
                {!isSearch && (
                  <Stack
                    onClick={() => {
                      setIsSearch(!isSearch)
                    }}
                    cursor={'pointer'}
                  >
                    <SearchIcon
                      width={24}
                      height={24}
                      color={color ? 'white' : 'black'}
                    />
                  </Stack>
                )}
                {isSearch && (
                  <Stack
                    onClick={() => {
                      setIsSearch(!isSearch)
                    }}
                    cursor={'pointer'}
                  >
                    <CloseIcon color={color ? 'white' : 'black'} />
                  </Stack>
                )}
                <Box position={'relative'}>
                  <Link href={RouterUrl.CART.ROOT} aria-label="Cart">
                    <CartIcon
                      width={28}
                      height={30}
                      color={color ? 'white' : 'black'}
                    />
                  </Link>
                  {totalItems > 0 && (
                    <Flex
                      position={'absolute'}
                      w={5}
                      h={5}
                      top={-1.5}
                      left={5}
                      bg={'red'}
                      color={'white'}
                      p={1}
                      borderRadius={'50%'}
                      fontSize={12}
                      alignItems={'center'}
                      justifyContent={'center'}
                      zIndex={10}
                    >
                      {totalItems}
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Container>

          <Drawer
            onClose={onClose}
            placement={'left'}
            isOpen={isOpen}
            size={'full'}
          >
            <DrawerOverlay />
            <DrawerContent bg={color ?? 'bg'}>
              <DrawerBody mt={5}>
                <Stack gap={2}>
                  <Flex justifyContent={'space-between'}>
                    <IconButton
                      onClick={onToggle}
                      icon={
                        isOpen ? (
                          <CloseIcon
                            w={5}
                            h={5}
                            color={color ? 'white' : 'black'}
                          />
                        ) : (
                          <HamburgerIcon w={8} h={8} />
                        )
                      }
                      variant={'ghost'}
                      aria-label={'Toggle Navigation'}
                      justifyContent={'start'}
                      _hover={{
                        bg: color ?? 'bg'
                      }}
                    />

                    <HStack spacing={3}>
                      <AuthFilter filter={true} isLogin={true}>
                        <Flex
                          alignItems={'center'}
                          gap={1.5}
                          bg={'white'}
                          px={{ base: 2, sm: 2, lg: 2.5, xl: 3 }}
                          py={{ base: 1.5, sm: 1.5, lg: 1, xl: 2 }}
                          borderRadius={15}
                          onClick={verifyAdvocado}
                          cursor={'pointer'}
                        >
                          <HabbitPointIcon w={4} h={4} />
                          <Text textStyle={'joinMembership'} w={'auto'}>
                            {avocado?.avocadoInfo?.pdpa == '1' ? (
                              (
                                avocado.avocadoInfo?.campaignBalances?.find(
                                  (item: any) => item.campaignType == 'loyalty'
                                ).points as number
                              ).toLocaleString()
                            ) : (
                              <div
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                Get Points & Rebate
                              </div>
                            )}
                          </Text>
                        </Flex>
                      </AuthFilter>
                      <CountrySelectNavCustom color={color} />
                    </HStack>
                  </Flex>
                  {!brand && (
                    <Link href={RouterUrl.HOME.ROOT} onClick={onClose}>
                      <Text
                        textStyle="linkItem"
                        color={color ? 'white !important' : 'black !important'}
                      >
                        Home
                      </Text>
                    </Link>
                  )}
                  <NavItems
                    items={navItems}
                    color={color}
                    brand={brand}
                    onClose={onClose}
                  />
                  <AuthFilter filter={true} isLogin={false}>
                    <Link href={RouterUrl.AUTH.LOGIN} onClick={onClose}>
                      <Text
                        textStyle="linkItem"
                        color={brand ? 'white' : 'black'}
                      >
                        Login
                      </Text>
                    </Link>
                  </AuthFilter>
                  <AuthFilter>
                    <Link href={RouterUrl.ACCOUNT.ROOT}>
                      <Text
                        textStyle="linkItem"
                        color={color ? 'white' : 'black'}
                      >
                        Account
                      </Text>
                    </Link>
                  </AuthFilter>
                  <AuthFilter filter={true} isLogin={true}>
                    <Link href={RouterUrl.AUTH.LOGIN} onClick={handleLogout}>
                      <Text
                        textStyle="linkItem"
                        color={color ? 'white' : 'black'}
                      >
                        Logout
                      </Text>
                    </Link>
                  </AuthFilter>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
        {loadAdvocadoPDPAModal()}
        {loadAdvocadoSignupModal()}
      </Container>
      {isSearch && mdMax && (
        <Container
          bg={color ?? 'bg'}
          borderTop={'1px solid #00000020'}
          py={2}
          display={'flex'}
          justifyContent={'center'}
          boxShadow={'md'}
          position={'absolute'}
          top={24}
          left={0}
          w={'100%'}
          zIndex={100}
        >
          <Search
            w={'100%'}
            colorIcon={color ? 'white' : 'black'}
            colorText={color ? 'white' : 'black'}
          />
        </Container>
      )}
    </Box>
  )
}
