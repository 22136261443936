import React from 'react'
import { IconPropsType } from './icon-props'

export const UserIcon = ({ width = 18, height = 20, color }: IconPropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_2)">
        <path
          d="M9.45999 10.05C11.9287 10.05 13.93 8.04873 13.93 5.58002C13.93 3.1113 11.9287 1.11002 9.45999 1.11002C6.99128 1.11002 4.98999 3.1113 4.98999 5.58002C4.98999 8.04873 6.99128 10.05 9.45999 10.05Z"
          stroke={color || 'black'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.32997 18.79H15.6C15.8543 18.7894 16.105 18.7296 16.3322 18.6154C16.5595 18.5012 16.757 18.3357 16.9093 18.132C17.0616 17.9283 17.1644 17.692 17.2097 17.4418C17.2549 17.1915 17.2413 16.9341 17.17 16.69L16.59 14.69C16.4679 14.274 16.2639 13.8867 15.9898 13.5508C15.7158 13.2148 15.3773 12.9371 14.9943 12.734C14.6114 12.5308 14.1916 12.4064 13.7598 12.3679C13.328 12.3295 12.8928 12.3778 12.48 12.51L11.6 12.79C10.2995 13.2085 8.90048 13.2085 7.59997 12.79L6.36997 12.42C5.95894 12.3012 5.52845 12.265 5.10335 12.3134C4.67824 12.3619 4.26695 12.4941 3.8932 12.7023C3.51944 12.9106 3.19064 13.1908 2.92577 13.5268C2.6609 13.8628 2.4652 14.248 2.34997 14.66L1.75997 16.66C1.68297 16.906 1.66501 17.1666 1.70757 17.4208C1.75013 17.675 1.852 17.9156 2.00492 18.1231C2.15784 18.3306 2.35751 18.4991 2.58773 18.6149C2.81795 18.7308 3.07224 18.7908 3.32997 18.79Z"
          stroke={color || 'black'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_2">
          <rect
            width="17.04"
            height="19.18"
            fill="white"
            transform="translate(0.940002 0.360016)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
