import React, { Fragment } from 'react'
import { Stack, Text } from '@chakra-ui/react'
import { useFooter } from '@lib/hooks/use-footer'
import { FooterInfo } from '@common/interface/footer'
import Link from 'next/link'
import { ListHeader } from '@components/footer'

const mapHandlePage = (handle: string, content: string) => {
  if (handle === 'our-story') {
    return handle
  }
  return content.trim().length === 0 ? `${handle}-page` : handle
}

const FooterInfoDetail = ({ infos }: { infos: FooterInfo[] }) => {
  return (
    <Stack align={'flex-start'} spacing={2}>
      <ListHeader>{infos[0]?.category?.name}</ListHeader>
      {infos.map((info) => {
        let root = process.env.NEXT_PUBLIC_FRONTEND_DOMAIN as string

        if (typeof window !== 'undefined') {
          root = window.location.origin
        }
        const link = info.is_anchor
          ? `${root}/${mapHandlePage(info.page?.handle, info.page?.content)}#${
              info.handle
            }`
          : `${root}/${mapHandlePage(info.handle, info.content)}`

        return (
          <Link key={info.id} href={link}>
            <Text textStyle="listItem">{info.title}</Text>
          </Link>
        )
      })}
    </Stack>
  )
}

const FooterInfo: React.FC = () => {
  const { groupInfos } = useFooter()

  return (
    <>
      {groupInfos.map((g) => (
        <FooterInfoDetail key={g.category_id} infos={g.infos} />
      ))}
    </>
  )
}

export default FooterInfo
